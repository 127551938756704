import React from 'react';
import PropTypes from 'prop-types';

function Timeline() {
  return (
    <div className="relative">
      <div className="absolute h-full top-0 border-r-2 border-dashed border-coral" style={{ width: '50%' }}></div>
      <TimelineItem
        label="2014"
        title="Bachelor of Physiotherapy"
        subtitles={['India']}
      />
      <TimelineItem
        label="2015"
        title="MS in Rehabilitation Sciences"
        subtitles={['PT in Neuromuscular concentration', 'University of Pittsburgh']}
      />
      <TimelineItem
        label="2018"
        title="Doctor of Physical Therapy"
        subtitles={['Northeastern University']}
      />
      <TimelineItem
        label="2019"
        title="RESNA Certified Assistive Technology Professional"
      />
      <TimelineItem
        label="2020"
        title="TinyTots PT on Instagram!"
      />
      <TimelineItem
        label="2020"
        title="Moved to Canada"
      />
    </div>
  );
}

const TimelineItem = ({ label, title, subtitles = [] }) => (
  <div className="relative p-6 bg-white border-2 border-dashed border-coral rounded text-center mb-24 last:mb-0 z-10">
    <div className="absolute w-full text-center left-0 right-0" style={{ top: '-16px' }}>
      <span className="inline-block bg-purple text-xs text-white font-bold tracking-widest px-4 py-1 rounded">
        {label}
      </span>
    </div>
    <div className="font-normal text-lg text-purple pt-1">
      {title}
    </div>
    <div className="leading-relaxed">
      {subtitles.map((s) => <div key={s}>{s}</div>)}
    </div>
  </div>
);
TimelineItem.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitles: PropTypes.arrayOf(PropTypes.string),
};

export default Timeline;
