import React from 'react';

function AboutText() {
  return (
    <div className="text-lg">
      <div className="font-serif text-2xl text-purple italic">But if you have questions like...</div>
      <ol className="list-none">
        <li className="mt-4">What’s the next milestone?</li>
        <li className="mt-3">Is my child behind?</li>
        <li className="mt-3">Should they be crawling by now?</li>
        <li className="mt-3">What’s a good seat for them?</li>
        <li className="mt-3">When can I start tummy time?</li>
        <li className="mt-3">How do I play with my child?</li>
      </ol>
      <div className="mt-6">You are in the right place!</div>

      <div className="font-serif text-2xl text-purple italic mt-8">
        Hello, I’m Shruti and I’m so excited you’re here!
      </div>

      <div className="mt-4">
        I’m a pediatric Physical Therapist and certified Assistive Technology Professional (licensed in the US).
        I specialize in working with the birth-three population, and it has presented me with the
        unique opportunity of helping new parents (let’s not forget the dads!)
        and amazing littles reach their potential.
      </div>
      <div className="mt-4">
        There’s so much information available online, and Dr. Google can be overwhelming.
        I’m here to help and provide research-based information about your Little One’s development.
        As a neuroscience and neurodevelopment nerd, I’m always learning so trust me when I say you’ll get
        accurate information! I get inspired at work every day and hope to share some of that inspiration with you.
      </div>

      <div className="font-serif text-2xl text-purple italic mt-8">
        Here are a few things you should know:
      </div>
      <div className="mt-4">
        <ul className="list-disc list-outside pl-5">
          <li>
            I believe that Moms have the BEST instincts and if you’re ever in doubt about your
            little’s development, trust your #MomGut.
          </li>
          <li>
            I’m a strong advocate for inclusion, in all walks of life.
            I love working with families and kiddos with special needs.
          </li>
          <li>
            Knowledge is power and knowing what normal development looks like can help you
            nurture your little through purposeful play.
          </li>
        </ul>
      </div>
      <div className="mt-4">
        My goal is to empower you through information and education. After all, empowered parents empower children!
      </div>
    </div>
  );
}

export default AboutText;
