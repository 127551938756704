import React from 'react';

import Layout from 'src/components/layout';
import SEO from 'src/components/seo';

import Banner from 'src/components/banner';
import Footer from 'src/components/footer';
import Instagram from 'src/components/instagram';
import Newsletter from 'src/components/newsletter';

import AboutSection from 'src/components/about';
import AboutText from 'src/components/about-text';
import TimelineSection from 'src/components/timeline';

import SPImage from 'src/images/self/sp-about.jpg';

function AboutPage() {
  return (
    <Layout>
      <SEO
        keywords={[`TinyTots PT`, `Shruti Pradhan`]}
        title="About"
      />
      <Banner>
        <span className="border-b-2 border-yellow">About Me</span>
      </Banner>
      <AboutSection title="Dr. Shruti Pradhan, PT, DPT" image={SPImage}>
        <div className="text-xl mb-4">
            Hello! I’m so glad you’re here!
          </div>
          <div className="text-xl">
            Motherhood is rewarding and amazing and life changing. However, it comes with worrying,
            and a boatload of questions about EVERYTHING and sometimes even #MomGuilt.
            Let’s start with the fact that YOU are an amazing Mom. That’s why you’re here!
          </div>
      </AboutSection>
      <div className="bg-gray-200 py-10 md:py-20">
        <div className="max-w-6xl mx-auto px-8 lg:px-4 xl:px-8">
          <div className="flex items-center flex-wrap md:flex-no-wrap justify-between">
            <div className="w-full md:w-2/3 p-6 md:p-10 bg-white shadow rounded mb-20 md:mb-0 md:mr-12">
              <AboutText />
            </div>
            <div className="w-full md:w-1/3">
              <TimelineSection />
            </div>
          </div>
        </div>
      </div>
      <Newsletter uid="2d516454d2" />
      <Instagram bgColor="gray-100" />
      <Footer />
    </Layout>
  );
}

export default AboutPage;
